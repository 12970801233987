import * as Types from '@relatable/gql/generated-base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'AccountType', admin?: { __typename?: 'AdminAccount', id: number, first_name?: string | null, last_name?: string | null, roles?: Array<string | null> | null } | null, client?: { __typename?: 'ClientAccount', last_name?: string | null, id: number, first_name?: string | null, clients_hubspot_company_id?: string | null, roles?: Array<string | null> | null } | null } | null };

export type ClientsOverviewQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.Clients_Bool_Exp>;
}>;


export type ClientsOverviewQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'clients', hubspot_company_id: string, name?: string | null, projects: Array<{ __typename?: 'project', id: number, name: string, stub: string, clients_hubspot_company_id: string, archived?: boolean | null, is_ready_for_client_review: boolean, project_client_review?: { __typename?: 'project_client_review', id: number, is_submitted: boolean } | null, campaigns: Array<{ __typename?: 'campaign', id: number, stub: string, title: string, platform?: Types.Platform_Enum | null, market_ids: string | number[], campaign_users: Array<{ __typename?: 'campaign_user', id: number, state?: Types.Campaign_User_State_Enum | null, state_log?: any | null }>, campaign_client_sheet_latest?: { __typename?: 'campaign_client_sheet_latest', min_creators?: number | null, state?: Types.Campaign_Client_Sheet_State_Enum | null } | null }> }> }> };


export const MeDocument = gql`
    query me {
  me {
    admin {
      id
      first_name
      last_name
      roles
    }
    client {
      last_name
      id
      first_name
      clients_hubspot_company_id
      roles
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ClientsOverviewDocument = gql`
    query clientsOverview($where: clients_bool_exp) {
  clients(where: $where, limit: 50) {
    hubspot_company_id
    name
    projects(order_by: {created_at: desc}) {
      id
      name
      stub
      clients_hubspot_company_id
      archived
      is_ready_for_client_review
      project_client_review {
        id
        is_submitted
      }
      campaigns {
        id
        stub
        title
        platform
        market_ids
        campaign_users {
          id
          state
          state_log
        }
        campaign_client_sheet_latest {
          min_creators
          state
        }
      }
    }
  }
}
    `;

/**
 * __useClientsOverviewQuery__
 *
 * To run a query within a React component, call `useClientsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientsOverviewQuery(baseOptions?: Apollo.QueryHookOptions<ClientsOverviewQuery, ClientsOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsOverviewQuery, ClientsOverviewQueryVariables>(ClientsOverviewDocument, options);
      }
export function useClientsOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsOverviewQuery, ClientsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsOverviewQuery, ClientsOverviewQueryVariables>(ClientsOverviewDocument, options);
        }
export function useClientsOverviewSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientsOverviewQuery, ClientsOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientsOverviewQuery, ClientsOverviewQueryVariables>(ClientsOverviewDocument, options);
        }
export type ClientsOverviewQueryHookResult = ReturnType<typeof useClientsOverviewQuery>;
export type ClientsOverviewLazyQueryHookResult = ReturnType<typeof useClientsOverviewLazyQuery>;
export type ClientsOverviewSuspenseQueryHookResult = ReturnType<typeof useClientsOverviewSuspenseQuery>;
export type ClientsOverviewQueryResult = Apollo.QueryResult<ClientsOverviewQuery, ClientsOverviewQueryVariables>;